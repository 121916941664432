(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  if ($(window).width() < 420) {
    $('.desktop_vid').remove();
  } else {
    $('.mobile_vid').remove();
  }

  $('.top-lvl').on('click', function () {
    $(this).find('.m-sub').slideToggle();
    $(this).toggleClass('active'); // $(this).find('.question_marker').toggleClass('active');
  });
  $('.hover-copy').on('mouseover', function () {
    $('.hover-copy').removeClass('active');
    $(this).addClass('active');
    var postHovered = $(this).data("hover");
    $('.hover-item').each(function () {
      if ($(this).attr('id') === postHovered) {
        $(this).addClass('show');
      } else {
        $(this).removeClass('show');
      }
    });
    var altSideBar = $('.side-bar');
    var sideBar = $(this).find('.side-bar');
    TweenMax.to(altSideBar, 0.5, {
      height: 0
    });
    TweenMax.fromTo(sideBar, 0.5, {
      height: 0
    }, {
      height: 300
    });
  });
  $('.load-image').imagesLoaded({
    background: true
  }, function () {
    featureBoxHeight();
    membershipBoxHeight();
    loadComplete();
  });
  $(window).resize(function () {
    featureBoxHeight();
    membershipBoxHeight();
  });
  var tl = new TimelineMax();
  tl.to('.loading-spin', 1, {
    rotation: 30,
    ease: Bounce.easeOut,
    repeat: -1,
    repeatDelay: 0.5
  });

  function loadComplete() {
    $('.preloader').addClass('is-loaded');
    $('body').removeClass('loading');
  }

  var wrapper = $(".wrapper"),
      $menu = $(".nav"),
      $window = $(window);
  $menu.on("click", "a", function () {
    var $this = $(this),
        href = $this.attr("href"),
        topY = $(href).offset().top - 110;
    TweenMax.to($window, 3, {
      scrollTo: {
        y: topY,
        autoKill: true
      },
      ease: Power3.easeOut
    });
    return false;
  });
  $('.back-to-top').each(function () {
    $(this).click(function () {
      $('html,body').animate({
        scrollTop: 0
      }, 'slow');
      return false;
    });
  }); // var $hamburger = $('.hamburger'),
  //     $mobilenav = $('.m-nav'),
  //     $wrapper = $('.wrapper');
  // $header = $('.header');
  // $hamburger.click(function(e) {
  //     $hamburger.toggleClass('is-active');
  //     $mobilenav.toggleClass('show');
  //     $wrapper.toggleClass('fixed');
  //     $header.toggleClass('fixed');
  // });
  // $('.m-nav li a').click(function() {
  //     $wrapper.removeClass('fixed');
  //     $hamburger.removeClass('is-active');
  //     $mobilenav.removeClass('show');
  //     $header.removeClass('fixed');
  // });

  var controlit = $("#burger");
  var menuToggle = new TimelineMax({
    paused: true,
    reversed: true
  });
  var mNavToggle = new TimelineMax({
    paused: true,
    reversed: true
  });
  menuToggle.set(controlit, {
    className: "closemenu"
  }).set(controlit, {
    className: "openmenu"
  }).to(' .burger-mid', 0.2, {
    x: -20
  }, 'burg').add('rotate').to(' .burger-top', 0.2, {
    y: '5'
  }, 'rotate').to(' .burger-bot', 0.2, {
    y: '-5'
  }, 'rotate').to(' .burger-top', 0.2, {
    rotationZ: 45,
    transformOrigin: '50% 50%'
  }, 'rotate').to(' .burger-bot', 0.2, {
    rotationZ: -45,
    transformOrigin: '50% 50%'
  }, 'rotate');
  mNavToggle.to('.m-nav', 0.4, {
    autoAlpha: 1
  }, 'burg').from('.m-nav-item', 0.4, {
    scale: 0.8
  }, 'burg').to('.m-nav-item', 0.4, {
    scale: 1,
    ease: Back.easeOut.config(1.7)
  }, 'burg'); // .set('#burger .mid', {opacity:0})//temp fix for stupid iOS rotate y bug

  controlit.click(function () {
    menuToggle.reversed() ? menuToggle.restart() : menuToggle.reverse();
    mNavToggle.reversed() ? mNavToggle.restart() : mNavToggle.reverse();
  });
  $('.m-nav li a').click(function () {
    menuToggle.reversed() ? menuToggle.restart() : menuToggle.reverse();
    mNavToggle.reversed() ? mNavToggle.restart() : mNavToggle.reverse();
  }); // $(window).scroll(function() {
  //     var scroll = $(window).scrollTop();
  //     if (scroll > 100) {
  //         var tlLogo = new TimelineMax();
  //         tlLogo.staggerTo('.remove', 0.1, { y: -100, ease: Power0.easeNone, transformOrigin: "50% 50%" }, 0.1);
  //     } else {
  //         var tlLogo = new TimelineMax();
  //         tlLogo.staggerTo('.remove', 0.1, { y: 0, ease: Power0.easeNone, transformOrigin: "50% 50%" }, 0.1);
  //     }
  // });

  $('input').focus(function () {
    $(this).data('placeholder', $(this).attr('placeholder')).attr('placeholder', '');
  }).blur(function () {
    $(this).attr('placeholder', $(this).data('placeholder'));
  });
  $('.block_slider').slick({
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    centerMode: false,
    arrows: true,
    autoplaySpeed: 4000,
    speed: 500,
    cssEase: 'linear',
    prevArrow: '<button type="button" class="prev"><img src="assets/images/left-arrow-gallery.png" /></button>',
    nextArrow: '<button type="button" class="next"><img src="assets/images/right-arrow-gallery.png" /></button>',
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 420,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $('.hero-slider').slick({
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    autoplaySpeed: 4000,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    pauseOnHover: false
  });
  var $status = $('.count');
  var $slickElement = $('.full-gallery');
  $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    $status.text(i + '/' + slick.slideCount);
  });
  $('.full-gallery').slick({
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    autoplaySpeed: 4000,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    pauseOnHover: false
  });
  $('.gallery').slick({
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    centerMode: false,
    arrows: true,
    autoplaySpeed: 4000,
    speed: 500,
    cssEase: 'linear',
    prevArrow: '<button type="button" class="prev"><img src="assets/images/left-arrow-gallery.png" /></button>',
    nextArrow: '<button type="button" class="next"><img src="assets/images/right-arrow-gallery.png" /></button>',
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 420,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $('.hover-copy-wrapper').slick({
    autoplay: false,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    arrows: false,
    speed: 300,
    fade: false,
    cssEase: 'linear',
    asNavFor: '.hover-gallery-wrapper',
    focusOnSelect: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: true,
        infinite: true,
        dots: false,
        prevArrow: '<button type="button" class="prev"><img src="assets/images/left-arrow-gallery.png" /></button>',
        nextArrow: '<button type="button" class="next"><img src="assets/images/right-arrow-gallery.png" /></button>'
      }
    }]
  });
  var $slider = $('.hover-gallery-wrapper').slick({
    autoplay: false,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    arrows: false,
    speed: 300,
    fade: true,
    cssEase: 'linear'
  });
  $(".hover-copy").on('mouseover', function () {
    var $slideIndex = $(this).attr('data-slick-index');
    $('.hover-copy').removeClass('active');
    $(this).addClass('active');
    $('.hover-gallery-wrapper').slick('slickGoTo', parseInt($slideIndex));
  });
  var isSlicked = false;

  if ($(window).width() < 620 && !isSlicked) {
    // $('.blog-slide').slick({
    //     autoplay: true,
    //     dots: false,
    //     infinite: false,
    //     slidesToShow: 1,
    //     arrows: true,
    //     autoplaySpeed: 4000,
    //     speed: 300,
    //     fade: false,
    //     cssEase: 'linear',
    //     pauseOnHover: true,
    //     prevArrow: '<button type="button" class="prev"><img src="assets/images/left-arrow-gallery.png" /></button>',
    //     nextArrow: '<button type="button" class="next"><img src="assets/images/right-arrow-gallery.png" /></button>'
    // });
    // $('.area-slide').slick({
    //     autoplay: true,
    //     dots: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     arrows: true,
    //     autoplaySpeed: 4000,
    //     speed: 300,
    //     fade: false,
    //     cssEase: 'linear',
    //     pauseOnHover: true,
    //     prevArrow: '<button type="button" class="prev"><img src="assets/images/left-arrow-gallery.png" /></button>',
    //     nextArrow: '<button type="button" class="next"><img src="assets/images/right-arrow-gallery.png" /></button>'
    // });
    // $('.meeting-slide').slick({
    //     autoplay: true,
    //     dots: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     arrows: true,
    //     autoplaySpeed: 4000,
    //     speed: 300,
    //     fade: false,
    //     cssEase: 'linear',
    //     pauseOnHover: true,
    //     prevArrow: '<button type="button" class="prev"><img src="assets/images/left-arrow-gallery.png" /></button>',
    // nextArrow: '<button type="button" class="next"><img src="assets/images/right-arrow-gallery.png" /></button>'
    // });
    // $('.hover-copy-wrapper').slick({
    //     autoplay: true,
    //     dots: false,
    //     infinite: false,
    //     slidesToShow: 1,
    //     arrows: false,
    //     autoplaySpeed: 4000,
    //     speed: 300,
    //     fade: false,
    //     cssEase: 'linear',
    //     pauseOnHover: true,
    //     asNavFor: '.hover-gallery-wrapper'
    // });
    // $('.hover-gallery-wrapper').slick({
    //     autoplay: true,
    //     dots: true,
    //     infinite: false,
    //     slidesToShow: 1,
    //     arrows: false,
    //     autoplaySpeed: 4000,
    //     speed: 300,
    //     fade: false,
    //     cssEase: 'linear',
    //     pauseOnHover: true,
    //     asNavFor: '.hover-copy-wrapper'
    // });
    isSlicked = true;
  } //////////////////////////////////////////
  // There's need some way to destroy slick
  // and init it in the future may be.
  // So, we have var isSlicked which control
  // screen size and trigger slider automaticly.
  //////////////////////////////////////////


  $(window).on('resize', function () {
    if ($(window).width() >= 620 && isSlicked) {
      console.log('destroy');
      $('.blog-slide').slick('unslick');
      $('.area-slide').slick('unslick');
      $('.meeting-slide').slick('unslick');
      $('.hover-copy-wrapper').slick('unslick');
      $('.hover-gallery-wrapper').slick('unslick');
      isSlicked = false;
    } else {
      if ($(window).width() < 620 && !isSlicked) {
        // $('.blog-slide').slick({
        //     autoplay: true,
        //     dots: true,
        //     infinite: false,
        //     slidesToShow: 1,
        //     arrows: false,
        //     autoplaySpeed: 4000,
        //     speed: 300,
        //     fade: false,
        //     cssEase: 'linear',
        //     pauseOnHover: false
        // });
        $('.area-slide').slick({
          autoplay: true,
          dots: true,
          infinite: false,
          slidesToShow: 1,
          arrows: false,
          autoplaySpeed: 4000,
          speed: 300,
          fade: false,
          cssEase: 'linear',
          pauseOnHover: false
        }); // $('.meeting-slide').slick({
        //     autoplay: true,
        //     dots: true,
        //     infinite: false,
        //     slidesToShow: 1,
        //     arrows: false,
        //     autoplaySpeed: 4000,
        //     speed: 300,
        //     fade: false,
        //     cssEase: 'linear',
        //     pauseOnHover: false
        // });
        // $('.hover-copy-wrapper').slick({
        //     autoplay: true,
        //     dots: false,
        //     infinite: false,
        //     slidesToShow: 1,
        //     arrows: false,
        //     autoplaySpeed: 4000,
        //     speed: 300,
        //     fade: false,
        //     cssEase: 'linear',
        //     pauseOnHover: false,
        //     asNavFor: '.hover-gallery-wrapper'
        // });
        // $('.hover-gallery-wrapper').slick({
        //     autoplay: true,
        //     dots: true,
        //     infinite: false,
        //     slidesToShow: 1,
        //     arrows: false,
        //     autoplaySpeed: 4000,
        //     speed: 300,
        //     fade: false,
        //     cssEase: 'linear',
        //     pauseOnHover: false,
        //     asNavFor: '.hover-copy-wrapper'
        // });

        isSlicked = true;
      }
    }
  }); // if ($(window).width() < 421) {
  //     $('.hover-copy').on('mouseover', function() {
  //         $('.hover-copy').removeClass('active');
  //         $(this).addClass('active');
  //         var postHovered = $(this).data("hover");
  //         $('.hover-item').each(function() {
  //             if ($(this).attr('id') === postHovered) {
  //                 $(this).addClass('show');
  //             } else {
  //                 $(this).removeClass('show');
  //             }
  //         });
  //         var altSideBar = $('.side-bar');
  //         var sideBar = $(this).find('.side-bar');
  //         TweenMax.to(altSideBar, 0.5, { height: 0 });
  //         TweenMax.fromTo(sideBar, 0.5, { height: 0 }, { height: 300 });
  //     });
  // }

  $('.map-check').on('click', function () {
    var checkmark = $(this).find('.check');
    $('.map-check').removeClass('active');
    $(this).addClass('active');
    TweenMax.fromTo(checkmark, 0.5, {
      scale: 0,
      transformOrigin: "50% 50%"
    }, {
      scale: 1,
      transformOrigin: "50% 50%",
      ease: Bounce.easeOut
    });
  }); // $('.row').each(function() {
  //     var $this = $(this);
  //     if ($this.find('.col-1-3').length % 2 === 0) { //if looking for direct descendants then do .children('div').length
  //         $this.children().last().addClass('even');
  //     } else {
  //         $this.children().last().addClass('centered');
  //     }
  // });

  $('.top-half').each(function () {
    var halfHeight = $(this).closest('.section').outerHeight() / 2;
    $(this).css('height', halfHeight);
  });
  $('.bottom-bg-image').each(function () {
    var halfHeight = $(this).closest('.section').outerHeight() / 2;
    $(this).css('height', halfHeight);
  });
  var bounce_obj = $('.down-arrow');
  var duration = 1;
  var tlBounce = new TimelineMax({
    repeat: -1,
    repeatDelay: 3
  });
  tlBounce.set(bounce_obj, {
    y: 0
  }).to(bounce_obj, duration / 4, {
    y: +20,
    ease: Power2.easeOut
  }, 'bounceme').to(bounce_obj, duration / 2, {
    y: 0,
    ease: Bounce.easeOut,
    delay: duration / 4
  }, 'bounceme');
  var tlSpin = new TimelineMax({
    repeat: -1
  });
  tlSpin.staggerTo('.spin', 1, {
    rotation: 30,
    ease: Bounce.easeOut,
    transformOrigin: "50% 50%"
  }, 1);
  var tlRipple = new TimelineMax({
    repeat: -1
  });
  tlRipple.staggerTo('.ripple-ring', 0.5, {
    scale: 1.1,
    ease: Circ.easeInOut,
    transformOrigin: "50% 50%",
    rotation: 60
  }, 0.3).staggerTo('.ripple-ring', 0.2, {
    scale: 1,
    ease: Circ.easeInOut,
    transformOrigin: "50% 50%",
    rotation: -30
  }, 0.1);
  var controller = new ScrollMagic.Controller(); // var scene = new ScrollMagic.Scene({ triggerElement: ".top", triggerHook: 0 })
  //     .setPin(".top")
  //     .addTo(controller);

  var tlLogo = new TimelineMax();
  var logoRoll = tlLogo.staggerTo('.remove', 0.3, {
    y: -100,
    ease: Power0.easeNone,
    transformOrigin: "50% 50%"
  }, 0.1);
  new ScrollMagic.Scene({
    triggerElement: "#intro",
    triggerHook: 0.4,
    duration: 0
  }).setTween(logoRoll).addTo(controller);
  new ScrollMagic.Scene({
    triggerElement: "#intro",
    triggerHook: 0.4
  }).setClassToggle(".header", "dark").addTo(controller);
  var introHeight = $('#intro').outerHeight();
  var topHeight = $('.section.top').outerHeight();
  var introSection = introHeight + topHeight;

  if ($('#membership-home').length) {
    new ScrollMagic.Scene({
      triggerElement: "#membership-home",
      triggerHook: 0.7,
      duration: introSection
    }).setTween(".xs-xl-letter-scroller", {
      y: "120%",
      ease: Linear.easeNone
    }).addTo(controller);
  }

  if ($('#intro').length) {
    new ScrollMagic.Scene({
      triggerElement: "#intro",
      triggerHook: 0.7,
      duration: introSection
    }).setTween(".co-letter-scroller", {
      y: "120%",
      ease: Linear.easeNone
    }).addTo(controller);
    new ScrollMagic.Scene({
      triggerElement: "#intro",
      triggerHook: 0.7,
      duration: introSection
    }).setTween(".petri-orange", {
      y: "60%",
      ease: Linear.easeNone
    }).addTo(controller);
    new ScrollMagic.Scene({
      triggerElement: "#intro",
      triggerHook: 0.7,
      duration: introSection
    }).setTween(".petri-grey", {
      y: "40%",
      ease: Linear.easeNone
    }).addTo(controller);
    new ScrollMagic.Scene({
      triggerElement: "#intro",
      triggerHook: 0.7,
      duration: introSection
    }).setTween(".whippin-piccadilly", {
      y: "40%",
      ease: Linear.easeNone
    }).addTo(controller);
    new ScrollMagic.Scene({
      triggerElement: "#intro",
      triggerHook: 0.7,
      duration: introSection
    }).setTween(".astley-gfx", {
      y: "40%",
      ease: Linear.easeNone
    }).addTo(controller);
  }

  $(".slide").each(function (index, elem) {
    var tween = TweenMax.fromTo(elem, 0.5, {
      opacity: 0,
      y: 100
    }, {
      opacity: 1,
      y: 0
    });
    new ScrollMagic.Scene({
      triggerElement: elem,
      triggerHook: "0.98"
    }).setTween(tween).addTo(controller);
  });
  $('.btn.membership').on("click", function (e) {
    e.preventDefault();
    var id = $(this).attr('data-modal');
    $(".modal-container").each(function () {
      $(this).removeClass('show');

      if ($(this).attr('id') == id) {
        $(this).addClass('show');
      }
    });
  });
  $('.modal-container').on("click", function (e) {
    $(this).removeClass('show');
  });
  $('.btn.close').on("click", function (e) {
    e.preventDefault();
    $(".modal-container").each(function () {
      $(this).removeClass('show');
    });
  });

  if ($('#instafeed').length) {
    var feed = new Instafeed({
      get: 'user',
      clientId: '6b1c237bacfc45418f654203287aa4c1',
      userId: '5160018845',
      accessToken: '5160018845.6b1c237.c0281e9f8e484ac5abdcf8626220f375',
      template: '<div class="insta-item"><a href="{{link}}" target="_blank"><img src="{{image}}" /></a></div>',
      resolution: 'standard_resolution',
      limit: 4
    });
    feed.run();
  }

  var meetingCount = $('.meeting-slide .col-1-3').length;

  if (meetingCount < 3) {
    $('.meeting-slide').addClass('flex');
  } else {
    $('.meeting-slide').removeClass('flex');
  }

  var memberCount = $('.membership-blocks .col-1-3').length;

  if (memberCount < 3) {
    $('.membership-blocks').addClass('flex');
  } else {
    $('.membership-blocks').removeClass('flex');
  } // NOT NEEDED??? //
  // $("#detail-scroll.down").on('click', function() {
  //     $('html,body').animate({
  //             scrollTop: $(".info").offset().top
  //         },
  //         600);
  //     $(this).addClass('up');
  //     $(this).removeClass('down');
  // });
  // $('.contact-container').click(function() {
  //     $('html,body').animate({
  //         scrollTop: 0
  //     }, 'slow');
  //     $('#detail-scroll').addClass('down');
  //     $('#detail-scroll').removeClass('up');
  //     return false;
  // });
  // $('.gallery-container').slick({
  //     autoplay: true,
  //     dots: false,
  //     infinite: true,
  //     slidesToShow: 3,
  //     arrows: true,
  //     cssEase: 'linear',
  //     autoplaySpeed: 2000,
  //     speed: 300,
  //     centerMode: true,
  //     centerPadding: '30px',
  //     pauseOnHover: true,
  //     swipeToSlide: true,
  //     prevArrow: '<button type="button" data-role="none" class="prev-arrow" aria-label="Previous" role="button"></button>',
  //     nextArrow: '<button type="button" data-role="none" class="next-arrow" aria-label="Next" role="button"></button>',
  //     responsive: [{
  //         breakpoint: 560,
  //         settings: {
  //             slidesToShow: 2
  //         },
  //         breakpoint: 420,
  //         settings: {
  //             slidesToShow: 1
  //         }
  //     }]
  // });
  // $('.swirl-image').slick({
  //     autoplay: true,
  //     dots: false,
  //     infinite: true,
  //     slidesToShow: 1,
  //     arrows: false,
  //     autoplaySpeed: 4000,
  //     speed: 1000,
  //     fade: true,
  //     cssEase: 'linear',
  //     pauseOnHover: false
  // });
  // $('.membership-container').each(function() {
  //     if ($(this).hasClass('hidden')) {
  //         $(this).closest('.col-1-3').addClass("squeeze");
  //     }
  // });
  // $('.benefits-btn').click(function() {
  //     $('#benefits').slideDown("slow");
  //     $('html, body').animate({
  //         scrollTop: $("#benefits").offset().top
  //     }, 2000);
  // });
  // $('.close-list').click(function() {
  //     $('#benefits').slideUp("slow");
  // });
  // $("[data-fancybox]").fancybox({
  //     animationEffect: "fade"
  // });
  // $('[data-fancybox]').click(function() {
  //     $('.gallery-container').slick('slickPause');
  // });
  // $('.membership-internal').click(function(e) {
  //     e.preventDefault();
  //     $(this).toggleClass('flip');
  // });


  function membershipBoxHeight() {
    var membershipHeight = $('.membership-image').height();
    var membershipContainer = $('.membership-container');
    $(membershipContainer).css("height", membershipHeight);
  }

  membershipBoxHeight();

  function featureBoxHeight() {
    var featureHeight = $('.feature-image').height();
    var featureContainer = $('.feature-container');
    $(featureContainer).css("height", featureHeight);
  }

  featureBoxHeight(); // function shuffle(array) {
  //     var m = array.length,
  //         t, i;
  //     while (m) {
  //         i = Math.floor(Math.random() * m--);
  //         t = array[m];
  //         array[m] = array[i];
  //         array[i] = t;
  //     }
  //     return array;
  // }
  // var classes = ["top-left", "top-right", "bottom-left", "bottom-right"];
  // $(".feature-image").each(function() {
  //     $(this).addClass(classes[~~(Math.random() * classes.length)]);
  // });
  // $('.feature-container').hover(function() {
  //     $(this).find('.feature-internal').addClass('flip');
  // }, function() {
  //     $(this).find('.feature-internal').removeClass('flip');
  // });
  // var size_li = $('.member-list').length;
  // var x = 3;
  // $('.member-list:lt(' + x + ')').show();
  // $('.load-more').click(function() {
  //     x = (x + 3 <= size_li) ? x + 3 : size_li;
  //     $('.member-list:lt(' + x + ')').show();
  //     $(this).toggle(x < size_li);
  // });

  var x, i, j, selElmnt, a, b, c;
  /* Look for any elements with the class "custom-select": */

  x = document.getElementsByClassName("custom-select");

  for (i = 0; i < x.length; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    /* For each element, create a new DIV that will act as the selected item: */

    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */

    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");

    for (j = 1; j < selElmnt.length; j++) {
      /* For each option in the original select element,
      create a new DIV that will act as an option item: */
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        var y, i, k, s, h;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;

        for (i = 0; i < s.length; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");

            for (k = 0; k < y.length; k++) {
              y[k].removeAttribute("class");
            }

            this.setAttribute("class", "same-as-selected");
            break;
          }
        }

        h.click();
      });
      b.appendChild(c);
    }

    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      /* When the select box is clicked, close any other select boxes,
      and open/close the current select box: */
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x,
        y,
        i,
        arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");

    for (i = 0; i < y.length; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }

    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  /* If the user clicks anywhere outside the select box,
  then close all select boxes: */


  document.addEventListener("click", closeAllSelect);
});
$(window).resize(function () {
  $('.top-half').each(function () {
    var halfHeight = $(this).closest('.section').outerHeight() / 2;
    $(this).css('height', halfHeight);
  });
  $('.bottom-bg-image').each(function () {
    var halfHeight = $(this).closest('.section').outerHeight() / 2;
    $(this).css('height', halfHeight);
  });
}); // window.addEventListener("DOMContentLoaded", function() {
//     // get the form elements defined in your form HTML above
//     var form = document.getElementById("colony_form");
//     var button = document.getElementById("form_submit");
//     var status = document.getElementById("form-status");
//     // Success and Error functions for after the form is submitted
//     function success() {
//       form.reset();
//       form.classList.add('hide');
//       status.classList.add('show');
//       status.innerHTML = "Thank you for getting in touch, we will contact you shortly.";
//     }
//     function error() {
//       status.innerHTML = "Oops! There was a problem.";
//     }
//     // handle the form submission event
//     form.addEventListener("submit", function(ev) {
//       ev.preventDefault();
//       var data = new FormData(form);
//       ajax(form.method, form.action, data, success, error);
//     });
//   });
//   // helper function for sending an AJAX request
//   function ajax(method, url, data, success, error) {
//     var xhr = new XMLHttpRequest();
//     xhr.open(method, url);
//     xhr.setRequestHeader("Accept", "application/json");
//     xhr.onreadystatechange = function() {
//       if (xhr.readyState !== XMLHttpRequest.DONE) return;
//       if (xhr.status === 200) {
//         success(xhr.response, xhr.responseType);
//       } else {
//         error(xhr.status, xhr.response, xhr.responseType);
//       }
//     };
//     xhr.send(data);
//   }

},{}]},{},[1]);
